<ul [class.expanded]="menuOpen === true" class="navmenu">
  <li *ngFor="let menuItem of menu">
      <a
        routerLink="{{ menuItem.link }}"
        (click)="toggleMenu($event)"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        >{{ menuItem.title }}</a
      >
  </li>

</ul>
