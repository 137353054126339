<section id="reception_service" class="introduction scrollto text-center">
  <header
    id="banner"
    class="scrollto clearfix shift-left"
    data-enllax-ratio=".5">
    <div class="row clearfix">
      <div
        class="section-heading"
        *ngIf="reception_service$ | async as reception_service"
      >
        <div class="section-title">{{ reception_service.title }}</div>
        <div class="bullets_title"> {{ reception_service.bullets_title }}</div>
        <ul class="bullet_points">
          <li *ngFor="let bullet of reception_service.bullet_points">{{ bullet }}</li>
        </ul>
        <a routerLink="{{ reception_service.buttonlink }}" class="button">{{
          reception_service.buttontext
        }}</a>
      </div>
    </div>
  </header>
</section>
