import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "../../shared/services/config.service";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.css"],
})
export class HomePageComponent implements OnInit {
  header$: Observable<any>;
  cols: number;

  constructor(
    private config: ConfigService,
    breakpointObserver: BreakpointObserver
  ) {
    breakpointObserver.observe([Breakpoints.Handset]).subscribe((result) => {
      this.cols = result.matches ? 1 : 2;
    });
  }

  ngOnInit() {
    this.getPageData("pages", 1);
  }

  getPageData(database: string, id?: number): void {
    this.header$ = this.config.getSettings(database, id);
  }
}
