import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { firstValueFrom } from "rxjs";
import { Contact } from "src/app/core/models";
import { ModalDialogOverview } from "../contact-modal/contact-modal.component";

export interface DialogData {
  resultText: string;
  infoText: string;
}

@Component({
  selector: "app-contact-form",
  templateUrl: "./contact-page.component.html",
  styleUrls: ["./contact-page.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactPageComponent implements OnInit, OnChanges {
  @Input() contact: Contact = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  };

  @Output() save = new EventEmitter<Contact>();

  contactForm: FormGroup;
  handleUpdateResponse: any;
  handleError: any;
  dialogTitle: string;
  dialogText: string;
  closeResult: string;

  constructor(
    public formBuilder: FormBuilder,
    public http: HttpClient,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      firstname: [this.contact.firstname, Validators.required],
      lastname: [this.contact.lastname, Validators.required],
      email: [
        this.contact.email,
        Validators.pattern(
          "^[a-zA-Z]{1,}[a-zA-Z0-9.-_]{1,}@[a-zA-Z]{1}[a-zA-Z.-]*[a-zA-Z]{1}[.][a-zA-Z]{2,}$"
        ),
      ],
      phone: [this.contact.phone],
      subject: [this.contact.subject, Validators.required],
      message: [this.contact.message, Validators.required],
      checkbox: [false, Validators.requiredTrue],
    });

    this.contactForm
      .get("checkbox")
      .disable({ onlySelf: this.contactForm.invalid });
  }

  ngOnChanges() {
    if (this.contact) {
      this.contactForm.patchValue({ ...this.contact });
    }
  }

  sendEmail(body: Contact) {
    const apiUrl = "https://zumsoy-app-backend.azurewebsites.net/sendEmail";

    this.http
      .post(apiUrl, body, {
        headers: { "Content-Type": "application/json" },
        observe: "response",
      })
      .subscribe({
        next: (v) => this.openDialog(v),
        error: (e) => console.error(e),
        complete: () => console.info("Email erfolgreich gesendet!"),
      });
  }

  submitOnClick() {
    if (this.contactForm.valid) {
      const formValues: Contact = this.contactForm.value;
      this.save.emit(formValues);
      this.sendEmail(formValues);
    }
  }

  openDialog(response: any): void {
    let resTxt = "Fehler";
    let infTxt =
      "Leider konnte Ihre Nachricht nicht übermittelt werden. Bitte versuchen Sie erneut oder senden Sie uns eine Email.";

    const statusCode = response?.status;
    if (statusCode === 200) {
      resTxt = "Erfolgreich";
      infTxt = "Ihre Nachricht wurde erfolgreich übermittelt.";
    }

    const dialogRef = this.dialog.open(ModalDialogOverview, {
      width: "250px",
      data: { resultText: resTxt, infoText: infTxt },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (statusCode === 200) {
        this.contactForm.reset();
      }
    });
  }
}
