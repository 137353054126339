<section id="bodyguard" class="introduction scrollto text-center">
  <header
  id="banner"
  class="scrollto clearfix shift-left"
  data-enllax-ratio=".5">
  <div class="row clearfix">

      <div class="section-heading" *ngIf="bodyguard$ | async as bodyguard">
        <div class="section-title">{{ bodyguard.title }}</div>
        <div class="bullets_title"> {{ bodyguard.bullets_title }}</div>
        <ul class="bullet_points">
          <li *ngFor="let bullet of bodyguard.bullet_points">{{ bullet }}</li>
        </ul>
        <a routerLink="{{ bodyguard.buttonlink }}" class="button">{{
          bodyguard.buttontext
        }}</a>
      </div>
    </div>
  </header>
</section>
