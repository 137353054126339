<section id="careers" class="introduction scrollto text-center">
  <header
    id="banner"
    class="scrollto clearfix shift-left"
    data-enllax-ratio=".5"
  >
    <div class="row clearfix">
      <div class="section-heading" *ngIf="careers$ | async as careers">
        <div class="section-title">{{ careers.title }}</div>
        <div class="text-section">
          Wir suchen ständig nach zuverlässigen Mitarbeitern <b>bundesweit</b>.
          Bitte bewerben Sie sich für entsprechende Stelle <br />
          per Email an
          <a class="company-email" href="mailto:info@zumsoy-security.de">
            info@zumsoy-security.de</a
          >.
        </div>
        <div class="section-subtitle">Offene Stellenangebote</div>

        <mat-grid-list
          cols="1"
          rowHeight="300px"
          gutterSize="30px"
          class="grid-jobs"
        >
          <mat-grid-tile
            class="job-description"
            *ngFor="let job of jobs$ | async"
          >
            <ul class="job-details">
              <span class="job-title"> {{ job.title }} </span>
              <li>
                Mögliche Job-Arten:
                <ul class="job-types">
                  <li *ngFor="let type of job.types">
                    {{ type }}
                  </li>
                </ul>
              </li>
              <li>Arbeitsort: {{ job.place }}</li>
              <li>
                Voraussetzungen:
                <ul class="job-conditions">
                  <li *ngFor="let condition of job.conditions">
                    {{ condition }}
                  </li>
                </ul>
              </li>
            </ul>
          </mat-grid-tile>
          <!-- <mat-grid-tile class="job-description"> </mat-grid-tile>
            <mat-grid-tile class="job-description"> </mat-grid-tile>
            <mat-grid-tile class="job-description"> </mat-grid-tile> -->
        </mat-grid-list>
      </div>
    </div>
  </header>
</section>
