<section id="gallery" class="introduction scrollto text-center">
  <header
  id="banner"
  class="scrollto clearfix shift-left"
  data-enllax-ratio=".5">
  <div class="row clearfix">

      <div class="section-heading" *ngIf="header$ | async as header">
        <div class="section-title">{{ header.title }}</div>
        <!-- <p class="section-subtitle">{{ header.description }}</p> -->
        <aside id="gallery" class="row text-center scrollto clearfix">
          <div *ngFor="let image of images$ | async; let i = index">
            <app-image-block [image]="image"></app-image-block>
          </div>
        </aside>
        <a routerLink="{{ header.buttonlink }}" class="button">{{
          header.buttontext
        }}</a>
      </div>
    </div>
  </header>
</section>
