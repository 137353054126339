import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { ContactRoutingModule } from './contact-routing.module';
import { MatCheckboxModule } from '@angular/material/checkbox';


@NgModule({
    declarations: [
        ContactPageComponent 
    ],
    imports: [
        CommonModule,
        ContactRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatDialogModule,
        MatButtonModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        MatCheckboxModule
    ]
})
export class ContactModule { }
