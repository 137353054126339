<div class="page-border" data-wow-duration="0.7s" data-wow-delay="0.2s">
  <div
    class="top-border wow fadeInDown animated"
    style="visibility: visible; animation-name: fadeInDown"
  ></div>
  <div
    class="right-border wow fadeInRight animated"
    style="visibility: visible; animation-name: fadeInRight"
  ></div>
  <div
    class="bottom-border wow fadeInUp animated"
    style="visibility: visible; animation-name: fadeInUp"
  ></div>
  <div
    class="left-border wow fadeInLeft animated"
    style="visibility: visible; animation-name: fadeInLeft"
  ></div>
</div>
<!--Main Content Area-->
<main id="uss-content">
  <div class="site-content">
    <img
      class="background-img"
      src="assets/images/banner-images/banner-image-1.jpg"
    />
    <app-navigation></app-navigation>
    <router-outlet>
      <app-spinner></app-spinner>
    </router-outlet>
    <app-footer></app-footer>
  </div>
</main>
