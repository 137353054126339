<div id="header" class="nav-collapse nav-solid">
  <div class="row clearfix">
    <div class="col-1">
      <nav id="nav-main">
        <app-navmenu [menu]="menu" [menuOpen]="true"></app-navmenu>

      </nav>
   
      <div id="nav-trigger">
        <span
          [class.open]="menuOpen == true"
          (click)="toggleMenu(!menuOpen)"
        ></span>
      </div>

      <nav id="nav-mobile">
        <app-navmenu
          [menu]="menu"
          *ngIf="menuOpen === true"
          (menuStatus)="toggleMenu($event)"
          [menuOpen]="menuOpen"
        ></app-navmenu>
      </nav>
    </div>
  </div>
</div>
