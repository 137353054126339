<!-- <a
  href="services/{{ image.link }}"
  data-featherlight="image"
  class="col-3 wow fadeIn"
  data-wow-delay="0.1s"
> -->

<!-- <a routerLink="{{ image.link }}" class="button">{{
  image.buttontext
}}</a> -->

<a
  routerLink="{{ image.link }}" class="button"
  data-featherlight="image"
  class="col-3 wow fadeIn"
  data-wow-delay="0.1s"
>

  <img src="assets/images/gallery-images/{{ image.name }}"/>
  <span class="content">{{ image.description }}</span>
</a>
