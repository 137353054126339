<section id="bodyguard" class="introduction scrollto text-center">
  <header
  id="banner"
  class="scrollto clearfix shift-left"
  data-enllax-ratio=".5">
  <div class="row clearfix">

      <div class="section-heading" *ngIf="city_patrol$ | async as city_patrol">
        <div class="section-title">{{ city_patrol.title }}</div>
        <div class="bullets_title"> {{ city_patrol.bullets_title }}</div>
        <ul class="bullet_points">
          <li *ngFor="let bullet of city_patrol.bullet_points">{{ bullet }}</li>
        </ul>
        <a routerLink="{{ city_patrol.buttonlink }}" class="button">{{
          city_patrol.buttontext
        }}</a>
      </div>
    </div>
  </header>
</section>
