import { DOCUMENT } from "@angular/common";
import { Component, Inject } from "@angular/core";
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterEvent,
} from "@angular/router";
import { Observable, of } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "Zumsoy Security & Service";
  isLoading$: Observable<boolean> = of(false);

  loading: boolean;

  constructor(@Inject(DOCUMENT) private _document, private router: Router) {
    this.router.events.subscribe((event: RouterEvent): void => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      } else if (event instanceof NavigationEnd) {
        if (this.router.url.toString() === "/services") {
          setTimeout(() => {
            this.loading = false;
          }, 2500);
        } else {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        }
      }
    });
  }
}
