<section id="reception_service" class="introduction scrollto text-center">
  <header
  id="banner"
  class="scrollto clearfix shift-left"
  data-enllax-ratio=".5">
  <div class="row clearfix">

      <div class="section-heading" *ngIf="mobile_services$ | async as mobile_services">
        <div class="section-title">{{ mobile_services.title }}</div>
        <div class="bullets_title"> {{ mobile_services.bullets_title }}</div>
        <ul class="bullet_points">
          <li *ngFor="let bullet of mobile_services.bullet_points">{{ bullet }}</li>
        </ul>
        <a routerLink="{{ mobile_services.buttonlink }}" class="button">{{
          mobile_services.buttontext
        }}</a>
      </div>
    </div>
  </header>
</section>
