<footer id="landing-footer" class="clearfix" *ngIf="footer$ | async as footer">
  <div class="row clearfix">
<!-- <ul class="footer-row">
        <li>
            <div class="impressum-in-footer">
              <a id="impressum" href="/impressum">
                Impressum
              </a>
          </div>
        </li>
        <li>
          <div class="copyright-in-footer">
            {{ copyrightText }}
          </div>
        </li>
        <li>
          <div class="sociales-in-footer">
            <app-social></app-social>
          </div>
        </li>
    </ul> -->

    <div class="footer-row top">
        <div class="impressum-in-footer">
            <a href="/impressum">
              Impressum
            </a>
        </div>
        <div>
          <span class="footer-separator">
            |
          </span>
        </div>
        <div class="dataprotection-in-footer">
          <a href="/data-protection">
            Datenschutz
          </a>
        </div>
    </div>
    <div class="footer-row middle">
      <div class="sociales-in-footer">
        <app-social></app-social>
      </div>
    </div>
    <div class="footer-row bottom">
      <div class="copyright-in-footer">
        {{ copyrightText }}
      </div>
    </div>

  </div>
  </footer>
 