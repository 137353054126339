<section id="contact" class="secondary-color text-center scrollto clearfix">
  <header
    id="banner"
    class="scrollto clearfix shift-left"
    data-enllax-ratio=".5"
  >
    <div class="container">
      <div class="headline-1">Kontakt</div>
      <div class="wrapper">
        <div class="company-info">
          <span class="company-info-title"
            >Zumsoy Security & Service UG (hb)</span
          >
          <ul class="contact-infos">
            <li>
              <i class="fa contact-icon fa-map-marker fa-lg"></i
              ><a href="https://maps.app.goo.gl/Fzq1NUGmWTTRAx6w8">
                Gartenfelder Str. 140, 13599 Berlin</a
              >
            </li>
            <li>
              <i class="fa contact-icon fa-phone fa-lg"></i
              ><a href="tel:+493076737127"> 030 76737127</a>
            </li>
            <li>
              <i class="fa contact-icon fa-envelope fa-lg"></i>
              <a href="mailto:info@zumsoy-security.de">
                info@zumsoy-security.de</a
              >
            </li>
            <li>
              <i class="fa contact-icon fa-globe fa-lg"></i>
              <a href="https://zumsoy-security.de/"> zumsoy-security.de</a>
            </li>
          </ul>
        </div>

        <div class="contactform">
          <div class="alert">Ihre Nachricht wurde versendet</div>
          <form (submit)="submitOnClick()" class="form" [formGroup]="contactForm">
            <div class="form-group not-full">
              <label for="firstname-input">Ihr Vorname</label>
              <input
                id="firstname-input"
                type="text"
                class="form-control"
                formControlName="firstname"
              />
              <span
                class="error-text"
                *ngIf="
                  contactForm.get('firstname').invalid &&
                  contactForm.get('firstname').touched
                "
                >Pflichtfeld! Bitte ausfüllen.</span
              >
            </div>

            <div class="form-group not-full">
              <label for="lastname-input">Ihr Nachname</label>
              <input
                id="lastname-input"
                type="text"
                class="form-control"
                formControlName="lastname"
              />
              <span
                class="error-text"
                *ngIf="
                  contactForm.get('lastname').invalid &&
                  contactForm.get('lastname').touched
                "
                >Pflichtfeld! Bitte ausfüllen.</span
              >
            </div>

            <div class="form-group not-full">
              <label for="email-input">Ihre Email-Adresse</label>
              <input
                id="email-input"
                type="email"
                class="form-control"
                formControlName="email"
                required
              />
              <span
                class="error-text"
                *ngIf="
                  contactForm.get('email').invalid &&
                  contactForm.get('email').touched
                "
                >Pflichtfeld! Bitte richtig ausfüllen.</span
              >
            </div>

            <div class="form-group not-full">
              <label for="phone-input">Ihre Telefonnummer</label>
              <input
                id="phone-input"
                type="tel"
                class="form-control"
                formControlName="phone"
              />
            </div>

            <div class="form-group full">
              <label for="subject-input">Betreff</label>
              <input
                id="subject-input"
                type="text"
                class="form-control"
                formControlName="subject"
              />
              <span
                class="error-text"
                *ngIf="
                  contactForm.get('subject').invalid &&
                  contactForm.get('subject').touched
                "
                >Pflichtfeld! Bitte ausfüllen.</span
              >
            </div>

            <div class="form-group full">
              <label for="message-input">Nachricht</label>
              <textarea
                id="message-input"
                type="text"
                rows="8"
                class="form-control"
                formControlName="message"
              ></textarea>
              <span
                class="error-text"
                *ngIf="
                  contactForm.get('message').invalid &&
                  contactForm.get('message').touched
                "
                >Pflichtfeld! Bitte ausfüllen.</span
              >
            </div>

            <div class="form-group not-full dataprotection">
              <div class="dataprotection-box">
                <mat-checkbox
                  class="checkbox-dataprotection"
                  [color]="'primary'"
                  formControlName="checkbox"
        
                  [disabled]="contactForm.invalid"
                >
                </mat-checkbox>
                <span class="dataprotection-row">
                  Ich stimme der
                  <a href="/data-protection" class="dataprotection-word">
                    Datenschutzerklärung </a
                  >zu.</span
                >
              </div>
            </div>
            <div class="form-group full">
              <button
                type="submit"
                [disabled]="!contactForm.valid"
                class="button btnsubmit link-lightbox"
              >
                Senden
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </header>
</section>
