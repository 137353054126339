<section id="impressum" class="secondary-color text-center scrollto clearfix">
  <header
    id="banner"
    class="scrollto clearfix shift-left"
    data-enllax-ratio=".5"
  >
    <div class="row clearfix">
      <div class="section-heading" *ngIf="impressum$ | async as impressum">
        <div class="headline-1">{{ impressum.title }}</div>
        <div class="impressum-text">
          <div class="tmg-infos">
            <span class="headline-2">Angaben gemäß § 5 TMG</span>
            <span class="text-section"
              >Zumsoy Security & Service UG (hb)<br />Khamzat Khamzatov</span
            >
            <span class="headline-3">Postanschrift:</span>
            <span class="text-section"
              >Gartenfelder Str. 140<br />13599 Berlin<br
            /></span>
            <span class="headline-3">Kontakt:</span>
            <span class="text-section">
              Telefon: 030 76737127<br />E-Mail: info@zumsoy-security.de
            </span>
            <span class="text-section"></span>
            <span class="headline-3">Vertreten durch:</span>
            <span class="text-section"
              >Geschäftsführer Khamzat Khamzatov<br
            /></span>
            <span class="text-section"></span>
            <span class="headline-3">Eingetragen am:</span>
            <span class="text-section"
              >Amtsgericht Charlottenburg<br />HRB242692B<br
            /></span>

            <span class="text-section"></span>
          </div>
          <div class="hints-to-website">
            <span class="headline-2">Hinweise zur Website</span>
            <span class="text-section"></span>
            <span class="headline-3">Urheberrechtliche Hinweise:</span>
            <span class="text-section">https://de.freepik.com<br /></span>
            <span class="text-section"></span>
            <span class="headline-2">Information gemäß § 36 VSBG</span>
            <span class="text-section">
              Gemäß § 36 VSBG (Verbraucherstreitbeilegungsgesetz – Gesetz über
              die alternative Streitbeilegung in Verbrauchersachen) erklärt der
              Betreiber dieser Website:
            </span>
            <span class="text-section">
              Wir sind weder bereit noch verpflichtet, an
              Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
              teilzunehmen.
            </span>
          </div>
        </div>
      </div>
    </div>
  </header>
</section>
