<section id="home">
  <div *ngIf="header$ | async as header">
    <header
      id="banner"
      class="scrollto clearfix shift-left"
      data-enllax-ratio=".5"
      [style.background]="
        'url(../assets/images/banner-images/' +
        header.image +
        ' ) no-repeat center top;'
      "
    >
      <div id="banner-content" class="row clearfix pull-center">
        <div class="col-37">
          <div class="section-heading">
   
            <span class="logo-img"></span>

            <p class="main-text-section">
              Willkommen bei Zumsoy Security & Service UG!
            </p>
            <span class="text-section">{{ header.description }}</span>
          </div>

          <div class="section-advantages">
            <p class="main-text-section">Unsere Mission</p>

            <span class="text-section"
              >Unsere Mission ist es, Ihnen ein sicheres und geschütztes Umfeld
              zu bieten. Wir verstehen, dass jede Situation einzigartig ist,
              daher bieten wir maßgeschneiderte Sicherheitslösungen an, die auf
              Ihre spezifischen Bedürfnisse zugeschnitten sind.</span
            >
          </div>

          <div class="section-reasons">
            <p class="main-text-section">Unsere Dienstleistungen</p>
            <mat-grid-list [cols]="cols" rowHeight="200px">
              <mat-grid-tile>
                <div class="reason">
                  <div class="reason-title">Objektschutz</div>
                  <div class="reason-description">
                    Wir sorgen dafür, dass Ihre Immobilien rund um die Uhr
                    sicher und geschützt sind.
                  </div>
                </div>
              </mat-grid-tile>
              <mat-grid-tile>
                <div class="reason">
                  <div class="reason-title">Brandwache</div>
                  <div class="reason-description">
                    Mit unserer Brandwache minimieren wir Feuerrisiken durch
                    kontinuierliche Überwachung und rasche Reaktion.
                  </div>
                </div>
              </mat-grid-tile>

              <mat-grid-tile>
                <div class="reason">
                  <div class="reason-title">Revierfahrer</div>

                  <div class="reason-description">
                    Mit ständigen Kontrollen und schnellen Reaktionen sorgen
                    unsere Revierfahrer für den Schutz Ihrer Objekte.
                  </div>
                </div>
              </mat-grid-tile>

              <mat-grid-tile>
                <div class="reason">
                  <div class="reason-title">Personen- und Begleitschutz</div>
                  <div class="reason-description">
                    Unser Team gewährleistet professionellen und diskreten
                    Schutz in jeder Situation.
                  </div>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </div>

          <div class="section-project-steps">
            <p class="main-text-section">Warum Zumsoy?</p>
            <div class="project-steps-description">
              Bei Zumsoy Security & Service UG setzen wir auf Professionalität,
              Zuverlässigkeit und Integrität. Unser Ziel ist es, Ihnen einen
              erstklassigen Service zu bieten, der Ihre Erwartungen übertrifft.
              Kontaktieren Sie uns noch heute, um mehr über unsere
              Dienstleistungen zu erfahren und herauszufinden, wie wir Ihnen
              helfen können, ein sicheres und geschütztes Umfeld zu schaffen.
            </div>
          </div>

          <div class="section-contact">
            <a routerLink="{{ header.buttonlink }}" class="button">{{
              header.buttontext
            }}</a>
          </div>
        </div>
      </div>
    </header>
  </div>
</section>
