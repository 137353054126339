import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";

import { ConfigService } from "../shared/services/config.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  // footer$: Observable<IFooter> = new Observable<IFooter>();
  footer$: Observable<any> = new Observable();
  copyrightText: string;
  constructor(private config: ConfigService) {}

  ngOnInit() {
    this.getPageData("pages", 3);
    this.getCopyrightText();
  }

  getPageData(database: string, id?: number): void {
    this.footer$ = this.config.getSettings(database, id);
  }

  getCopyrightText(): void {
    const currentYear = new Date().getFullYear();
    const copyrightText =
      "Copyright © " + currentYear + " Zumsoy Security & Service UG (hb)";
    this.copyrightText = copyrightText;
  }
}
