import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";

// Components
import { AppComponent } from "./app.component";
import { FooterComponent } from "./footer/footer.component";
import { NavigationComponent } from "./navigation/navigation.component";
import { NavmenuComponent } from "./navmenu/navmenu.component";
import { WebsiteBlockComponent } from "./website-block/website-block.component";

// Modules
import { AppRoutingModule } from "./app-routing.module";
import { ContactModule } from "./contact/contact.module";
import { HomeModule } from "./home/home.module";
import { ImpressumModule } from "./impressum/impressum.module";
import { NotfoundModule } from "./notfound/notfound.module";
import { ServicesModule } from "./services/services.module";

// Services
import { ConfigService } from "./shared/services/config.service";
import { InMemoryDataService } from "./shared/services/in-memory-data.service";

import {
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaV3Module,
} from "ng-recaptcha";

import { DataProtectionModule } from "./data-protection/data-protection.module";
import { BodyguardModule } from "./services-pages-separat/bodyguard/bodyguard.module";
import { CityPatrolModule } from "./services-pages-separat/city-patrol/city-patrol.module";
import { EventProtectionModule } from "./services-pages-separat/event-protection/event-protection.module";
import { MobileSecurityServicesModule } from "./services-pages-separat/mobile-security-services/mobile-security-services.module";
import { ObjectProtectionModule } from "./services-pages-separat/object-protection/object-protection.module";
import { ReceptionPorterServiceModule } from "./services-pages-separat/reception-porter-service/reception-porter-service.module";

import { MatDialogModule } from "@angular/material/dialog";

import { MatNativeDateModule } from "@angular/material/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "src/material.module";
import { CareersModule } from "./careers/careers.module";
import { ModalDialogOverview } from "./contact/contact-modal/contact-modal.component";
import { LoadingInterceptor } from "./loading.interceptor";
import { SpinnerComponent } from "./spinner/spinner.component";
import { LayoutModule } from '@angular/cdk/layout';
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavigationComponent,
    NavmenuComponent,
    WebsiteBlockComponent,
    ModalDialogOverview,
    SpinnerComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HomeModule,
    ImpressumModule,
    AppRoutingModule,
    HttpClientModule,
    NotfoundModule,
    ContactModule,
    ReactiveFormsModule,
    ServicesModule,
    FormsModule,
    BodyguardModule,
    ReceptionPorterServiceModule,
    ObjectProtectionModule,
    MobileSecurityServicesModule,
    CityPatrolModule,
    EventProtectionModule,
    DataProtectionModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MaterialModule,
    RecaptchaV3Module,
    RecaptchaFormsModule,
    RecaptchaModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule,
    LayoutModule,
    CareersModule,
    HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {
      dataEncapsulation: false,
      passThruUnknownUrl: true,
    }),
  ],
  providers: [
    ConfigService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: "6LcJkRwjAAAAAFXzO4ChoCp5GuEf9qvzy6PF5RGs",
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [ModalDialogOverview],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
