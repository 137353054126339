<section id="bodyguard" class="introduction scrollto text-center">
  <header
  id="banner"
  class="scrollto clearfix shift-left"
  data-enllax-ratio=".5">
  <div class="row clearfix">

      <div class="section-heading" *ngIf="event_protection$ | async as event_protection">
        <div class="section-title">{{ event_protection.title }}</div>
        <div class="bullets_title">{{ event_protection.bullets_title }}</div>
        <ul class="bullet_points">
          <li *ngFor="let bullet of event_protection.bullet_points">{{ bullet }}</li>
        </ul>
        <a routerLink="{{ event_protection.buttonlink }}" class="button">{{
          event_protection.buttontext
        }}</a>
      </div>
    </div>
  </header>
</section>
